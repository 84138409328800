import React from 'react'
import OwlCarousel from "react-owl-carousel";
const MarketingPenal = () => {
  return (
    <div className="col-sm-12 SimpleImageSlider-div">
    <OwlCarousel
      className="owl-theme"
      loop
      margin={10}
      nav
      items={1}
      autoplay={true}
      autoplayTimeout={3000}
      autoplayHoverPause={true}
     
    >
      <a
        className="item"
        href="https://www.swindia.com/swilerp-cloud/?utm_source=swilshare&utm_medium=referral&utm_campaign=swilerp_cloud"
        target={"_blank"}
      >
        <img
          src="https://swilindiacdn.blob.core.windows.net/swilshare/SwilERP-Cloud.png"
          className="d-block "
          alt="error"
        />
      </a>

      <a
        className="item"
        href="https://www.swindia.com/retailgraph-software/?utm_source=swilshare&utm_medium=referral&utm_campaign=retailgraph_erp"
        target={"_blank"}
     >
        <img
          src="https://swilindiacdn.blob.core.windows.net/swilshare/RetailGraph-Banner.png"
          className="d-block "
          alt="error"
        />
      </a>

      <a
        className="item"
        href="https://www.swindia.com/swilerp-software/?utm_source=swilshare&utm_medium=referral&utm_campaign=swilerp_pharmacy_software"
        target={"_blank"}
      >
        <img
          src="https://swilindiacdn.blob.core.windows.net/swilshare/slide-01.jpg"
          className="d-block "
          alt="error"
        />
      </a>

      <a
        className="item"
        href="https://www.swindia.com/mobile-app-solutions/?utm_source=swilshare&utm_medium=referral&utm_campaign=swilpos"
        target={"_blank"}
      >
        <img
          src="https://swilindiacdn.blob.core.windows.net/swilshare/slide-02.jpg"
          className="d-block "
          alt="error"
        />
      </a>
      <a
        className="item"
        href="https://www.swindia.com/mobile-app-solutions/?utm_source=swilshare&utm_medium=referral&utm_campaign=swilsort"
        target={"_blank"}
      >
        <img
          src="https://swilindiacdn.blob.core.windows.net/swilshare/slide-03.jpg"
          className="d-block "
          alt="error"
        />
      </a>

      <a
        className="item"
        href="https://www.swindia.com/mobile-app-solutions/?utm_source=swilshare&utm_medium=referral&utm_campaign=swildispatch"
        target={"_blank"}
      >
        <img
          src="https://swilindiacdn.blob.core.windows.net/swilshare/slide-04.jpg"
          className="d-block "
          alt="error"
        />
      </a>
    </OwlCarousel>

   
  </div>
  )
}

export default MarketingPenal