import React from "react";
import MarketingPenal from "./slide";


export const Left = (props) => {
  return (
    <div className="col-lg-4 mid_box_lft pl-0">
      <div className="mid_box_lft_frst">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Sender Information
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse "
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="info_box">
                  <div className="showdtl-div">
                    <span className="dtl-heading">company Name</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.companyName}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">GSTIN</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.gstin}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">PAN</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.pan}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">email</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.email}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">phone</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.phone}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">whatsapp</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.whatsapp}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Website</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.Website}
                    </span>
                  </div>

                  <hr />

                  <div className="showdtl-div">
                    <span className="dtl-heading">Address</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.address.fullAddress}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Station</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.address.city}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Pincode</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.address.pincode}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Country</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.sender?.address.country}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Receiver Information
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="info_box">
                  <div className="showdtl-div col-sm-12">
                    <span className="dtl-heading"> Customer Name</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.receiver?.CompanyName}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Contact</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.receiver?.ContactPerson}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">PAN</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.receiver?.pan}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">GSTIN</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.receiver?.gstin}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">email</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.receiver?.email}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">phone</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.receiver?.phone}
                    </span>
                  </div>

                  <hr />

                  <div className="showdtl-div">
                    <span className="dtl-heading">Address</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.receiver?.address.fullAddress}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Station</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.receiver?.address.city}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Pincode</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.receiver?.address.pincode}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Country</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.receiver?.address.country}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Document Details
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="info_box ">
                  <div className="showdtl-div">
                    <span className="dtl-heading">Transaction Type</span>
                    <span className="dtl-text">
                      {
                        props.transactiondtl?.transactionDetails
                          ?.transactionType
                      }
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Entry No</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.transactionDetails?.EntryNo}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Entry Date</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.transactionDetails?.EntryDate}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Currency Code</span>
                    <span className="dtl-text">
                      {props.transactiondtl?.transactionDetails?.CurrencyCode}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Tax Amount</span>
                    <span className="dtl-text">
                      {parseFloat(
                        props.transactiondtl?.transactionDetails?.totalTaxAmount
                      ).toFixed(2)}
                    </span>
                  </div>

                  <div className="showdtl-div">
                    <span className="dtl-heading">Net Amount</span>
                    <span className="dtl-text">
                      {parseFloat(
                        props.transactiondtl?.transactionDetails?.totalAmount
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<MarketingPenal />
    </div>
  );
};
