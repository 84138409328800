import React, { useEffect, useMemo, useState } from "react";
import fileDownload from "js-file-download";
import axios from "axios";
import { Worker, Viewer } from "@react-pdf-viewer/core";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import MarketingPenal from "./slide";

export const Right = (props) => {
  const azureblobfile = `${props.transactiondtl?.transactionDetails?.documentDetails?.document}`;
  const azureblobfilename = `${props.transactiondtl?.receiver?.CompanyName?.replaceAll(
    " ",
    "-"
  )}-${props.transactiondtl?.transactionDetails?.transactionType.replaceAll(
    " ",
    "-"
  )}-${props.transactiondtl?.transactionDetails?.EntryNo}.pdf`;
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <div className="col-md-8 mid_box_ryt" id="pdfviewMaindiv">
      <div className="invoice_preview">
        <div className="invoice_preview_lft">
          <p>{props.transactiondtl?.transactionDetails?.transactionType}</p>
          {/* <h3>{props.transactiondtl?.transactionDetails?.CurrencyCode} : {props.transactiondtl?.transactionDetails?.totalAmount}</h3> */}
        </div>
        <div className="invoice_preview_ryt">
          <ul className="preview_icon_list">
            <li className="preview_icon">
              <a
                href={`${props.transactiondtl?.transactionDetails?.documentDetails?.document}`}
                target="_blank"
              >
                <i className="bi bi-arrows-fullscreen"></i>
              </a>
            </li>
            <li
              className="preview_icon"
              onClick={() => handleDownload(azureblobfile, azureblobfilename)}
            >
              <a>
                <i className="bi bi-download"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* <embed  width={"100%"} height={500} src={props.transactiondtl?.transactionDetails?.documentDetails?.document}></embed > */}

      {/* <iframe src={props.transactiondtl?.transactionDetails?.documentDetails?.document} className="hidden-controls"></iframe> */}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
        <Viewer
          fileUrl={
            props.transactiondtl?.transactionDetails?.documentDetails?.document
          }
          tranformSize={(e) => console.log(e)}
          numPages={20}
          size={"Rect"}
        />
      </Worker>

      <iframe src={props.transactiondtl?.transactionDetails?.documentDetails?.document}></iframe>

      <div className="mob-view-show-penal">
        {window.screen.width <= 999 ? <MarketingPenal /> : null}
      </div>
    </div>
  );
};
