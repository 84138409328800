import React from "react";

export const Header = (props) => {
  return (
    <section className="header">
      <div className="container-fluid">
        <div className="row">
          <div className="mid-header col-sm-8">
            {props.transactiondtl?.sender?.logoImage ? (
              <img
                src={`${props.transactiondtl?.sender?.logoImage}`}
                alt="error"
              />
            ) : null}
            <div
              className="companyInfo"
              style={
                props.transactiondtl?.sender?.logoImage
                  ? { paddingLeft: "34px" }
                  : { paddingLeft: "0px" }
              }
            >
              <h2>{props.transactiondtl?.sender?.companyName}</h2>
              <ul className="social">

                {props.transactiondtl?.sender?.email ? 
                <li>
                  <a
                    href={`mailto:${props.transactiondtl?.sender?.email}`}
                    className="social_icon"
                    style={{ backgroundColor: "rgb(60 73 110)" }}
                  >
                    <i className="bi bi-envelope"></i>
                  </a>
                </li>
                 : null}
                     {props.transactiondtl?.sender?.Website ? 
                <li>
                  <a
                    href={`https://${props.transactiondtl?.sender?.Website}`}
                    target="_blank"
                    className="social_icon"
                    style={{ backgroundColor: "#FA6137" }}
                  >
                    <i className="bi bi-globe"></i>
                  </a>
                </li>
                : null}
                    {props.transactiondtl?.sender?.Website ? 
                <li>
                  <a
                    href={`//api.whatsapp.com/send?phone=${props.transactiondtl?.sender?.whatsapp}`}
                    target="_blank"
                    className="social_icon"
                    style={{ backgroundColor: "#29A71A" }}
                  >
                    <i className="bi bi-whatsapp"></i>
                  </a>
                </li>
                   : null}
              </ul>
            </div>
          </div>

          <div className="brand-logo-main col-sm-4">
            <div className="brand-logo">
              <img src="/images/swilshare-logo.png" />
            </div>
            <span className="powered-by"><img src="/images/powerd-by.png" /></span>
          </div>
        </div>
      </div>
    </section>
  );
};
